/* eslint-disable vue/valid-v-bind */
<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">
      Ajustes de inventario en almacenes
    </h1>

    <div class="control add-global-controls">
      <b-button
        type="is-primary"
        class="special-margin"
        @click="toogleShowEditted()"
        >{{
          showOnlyEditted
            ? "Mostrar todos los artículos"
            : "Mostrar solo artículos editados"
        }}</b-button
      >
    </div>

    <div class="invoice-add-item special-margin">
      <b-field
        class="invoice-add-secondary-item-second"
        label-position="inside"
        label="Almacén origen"
      >
        <!-- eslint-disable vue/no-use-v-if-with-v-for -->
        <b-select
          placeholder="Ejemplo: Almacén 1"
          icon="warehouse"
          expanded
          v-model="newWarehouseTransfer.CVEALM1"
          @input="(value) => firstWarehouseSelected(value)"
          :disabled="newWarehouseTransfer.CVEALM1 !== ''"
        >
          <option value="" disabled>Seleccione un almacén</option>
          <option
            v-for="warehouse in warehouses"
            :value="warehouse"
            :key="warehouse.CLAVE_ALMACEN"
          >
            {{ warehouse.NOMBRE_ALM }}
          </option>
        </b-select>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-second"
        label-position="inside"
        label="Fecha"
      >
        <b-datepicker
          v-model="newWarehouseTransfer.FECHA_TRAN"
          :show-week-number="true"
          :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
          :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
          :month-names="[
            'Enero',
            'Febrero',
            'Marzo',
            'Abril',
            'Mayo',
            'Junio',
            'Julio',
            'Agosto',
            'Septiembre',
            'Octubre',
            'Noviembre',
            'Diciembre',
          ]"
          placeholder="Fecha de creación"
          icon="calendar-today"
          trap-focus
        >
        </b-datepicker>
      </b-field>
      <b-field
        class="invoice-add-secondary-item-one"
        label-position="inside"
        label="Observaciones"
      >
        <b-input
          type="textarea"
          expanded
          v-model="newWarehouseTransfer.OBSER_TRAN"
        ></b-input>
      </b-field>
    </div>

    <div
      class="invoice-div-bottom-div"
      v-if="newWarehouseTransfer.CVEALM1 !== ''"
    >
      <b-table
        pagination-position="both"
        :data="articlesFormatted"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="TransferArticlesTable"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="asc"
        checkbox-position="left"
        default-sort="CLAVE_ART"
        detailed
        detail-key="_id"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_ART}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
      >
        <template #empty>
          <div class="has-text-centered">No hay almacenes o artículos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            :visible="column.display"
            v-bind="column"
            :key="column.field"
            :label="column.label"
          >
            {{ props.row[column.field] }}
          </b-table-column>
          <b-table-column
            v-for="column in props.row.defaultWarehouseArticles"
            :key="column._id"
            :label="column.NOMBRE_ALM"
            sortable
            :custom-sort="sortByWarehouseQuantity"
          >
            <b-field grouped class="add-global-modal-name">
              <b-field
                label="Artículos en almacén de origen"
                expanded
                label-position="inside"
                class="special-simple-margin"
              >
                <b-input
                  placeholder="Ejemplo: 40"
                  expanded
                  icon="numeric-1-circle-outline"
                  type="number"
                  step="any"
                  v-model="column.CANTIDAD"
                  disabled
                ></b-input>
              </b-field>
              <b-field
                label="Artículos a agregar"
                expanded
                label-position="inside"
                class="special-simple-margin"
              >
                <b-input
                  placeholder="Ejemplo: 40"
                  expanded
                  icon="numeric-1-circle-outline"
                  type="number"
                  step="any"
                  v-model="column.CANTIDAD_AGR"
                  @input="
                    (value) =>
                      changeTransfer(
                        props.row,
                        newWarehouseTransfer.CVEALM1,
                        value
                      )
                  "
                ></b-input>
              </b-field>
            </b-field>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <div>
                  <b-field
                    label="Presentación"
                    label-position="inside"
                    expanded
                  >
                    <b-select
                      placeholder="Ejemplo: Caja de 5 martillos"
                      icon="tape-measure"
                      v-model="props.row.selectedPresentation"
                      class="special-margin"
                      expanded
                    >
                      <option value="">Seleccione una presentación</option>
                      <option
                        v-for="presentation in props.row.PRESENTACIONES"
                        :value="presentation._id"
                        :key="presentation.NOMBRE"
                      >
                        {{ presentation.NOMBRE }}
                      </option>
                    </b-select>
                  </b-field>
                </div>
                <div
                  v-for="singlePresentation in props.row.PRESENTACIONES"
                  v-bind:key="singlePresentation._id"
                  class="special-big-margin single-articles-prices"
                  v-if="
                    singlePresentation._id === props.row.selectedPresentation
                  "
                >
                  <h3 class="title-expandable-item">
                    <strong>Presentación </strong>
                    <strong
                      ><span class="global-form-hint-color">{{
                        singlePresentation.NOMBRE
                      }}</span>
                    </strong>
                  </h3>

                  <b-field grouped class="special-margin">
                    <b-field
                      label="Valor de medida base del artículo (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 40"
                        expanded
                        icon="numeric-1-circle-outline"
                        type="number"
                        step="any"
                        :value="singlePresentation.FACTOR1"
                        disabled
                      ></b-input>
                    </b-field>

                    <b-field
                      label="Cantidad de medidas base que equivale la presentación (Requerido)"
                      expanded
                      label-position="inside"
                    >
                      <b-input
                        placeholder="Ejemplo: 40"
                        expanded
                        icon="numeric-2-circle-outline"
                        type="number"
                        step="any"
                        :value="singlePresentation.FACTOR2"
                        disabled
                      ></b-input>
                    </b-field>
                  </b-field>

                  <b-field
                    label="A transferir a almacén de destino"
                    expanded
                    label-position="inside"
                    class="special-simple-margin"
                  >
                    <b-input
                      placeholder="Ejemplo: 40"
                      expanded
                      icon="numeric-1-circle-outline"
                      type="number"
                      step="any"
                      @input="
                        (value) =>
                          changeTransferPresentation(
                            props.row,
                            newWarehouseTransfer.CVEALM1,
                            value
                          )
                      "
                    ></b-input>
                  </b-field>
                </div>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="transferArticles(false)"
        :disabled="
          newWarehouseTransfer.CVEALM1 === '' ||
          newWarehouseTransfer.CVEALM2 === ''
        "
        v-if="
          companyPaymentPlanModules &&
          companyPaymentPlanModules.purchase.addArticlesQuantityPending ===
            'S' &&
          permissions &&
          permissions.purchase.addArticlesQuantityPending === 'S'
        "
        >Crear transferencia pendiente</b-button
      >
      <b-button
        type="is-success"
        @click="transferArticles(true)"
        :disabled="
          newWarehouseTransfer.CVEALM1 === '' ||
          newWarehouseTransfer.CVEALM2 === ''
        "
        v-if="
          companyPaymentPlanModules &&
          companyPaymentPlanModules.purchase.addArticlesQuantity === 'S' &&
          permissions &&
          permissions.purchase.addArticlesQuantity === 'S'
        "
        >Ajustar artículos en almacén</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { isNumber } from "../../../utils/fns";
import PrintMultipleMaterialMovement from "./print-formats/PrintMultipleMaterialMovements.vue";

export default {
  name: "AddArticlesQuantity",
  components: {},
  data() {
    return {
      // Table values
      newWarehouseTransfer: {
        CLAVE_TRAN: 0,
        FECHA_TRAN: new Date(),
        CVEALM1: "",
        STATUS_TRA: "",
        OBSER_TRAN: "",
      },
      firstWarehouseDisabled: false,
      moment: moment,
      perPage: 25,
      checkedWarehouses: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_ART",
          label: "Clave de articulo",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
      ],
      showOnlyEditted: false,
    };
  },
  async created() {
    moment.locale("es");
    this.startingDateFormated = moment(new Date()).format("DD-MM-YYYY HH:mm");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    firstWarehouseSelected(value) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.articles.entries()) {
        let warehouseQuantity = 0;
        let defaultWarehouseArticles = [];
        let defaultWarehousesPresentationArticles = [];

        for (let singleArticleWarehouse of singleArticle.CANT_ALMACENES) {
          if (
            singleArticleWarehouse.CLAVE_ALMACEN ===
            this.newWarehouseTransfer.CVEALM1._id
          ) {
            warehouseQuantity = singleArticleWarehouse.CANTIDAD;
            break;
          }
        }

        defaultWarehouseArticles.push({
          CANTIDAD_AGR: 0,
          CANTIDAD: warehouseQuantity,
          CANTIDADINICIAL: warehouseQuantity,
          NOMBRE_ALM: this.newWarehouseTransfer.CVEALM1.NOMBRE_ALM,
          CLAVE_ALMACEN: this.newWarehouseTransfer.CVEALM1.CLAVE_ALMACEN,
          _id: this.newWarehouseTransfer.CVEALM1._id,
          display: true,
        });
        this.articles[singleArticleIndex].defaultWarehouseArticles =
          defaultWarehouseArticles;
      }
    },
    changeTransfer(article, warehouse, value) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.articles.entries()) {
        if (singleArticle._id === article._id) {
          for (const [
            singleWarehouseIndex,
            singleWarehouse,
          ] of singleArticle.defaultWarehouseArticles.entries()) {
            if (singleWarehouse._id === warehouse._id) {
              this.articles[singleArticleIndex].defaultWarehouseArticles[
                singleWarehouseIndex
              ].CANTIDAD =
                Number(
                  this.articles[singleArticleIndex].defaultWarehouseArticles[
                    singleWarehouseIndex
                  ].CANTIDADINICIAL
                ) + Number(value);

              this.articles[singleArticleIndex].defaultWarehouseArticles[
                singleWarehouseIndex
              ].CANTIDAD_AGR = Number(value);
            }
          }
        }
      }
    },
    changeTransferPresentation(article, warehouse, value) {
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.articles.entries()) {
        if (singleArticle._id === article._id) {
          for (const [
            singleWarehouseIndex,
            singleWarehouse,
          ] of singleArticle.defaultWarehouseArticles.entries()) {
            if (singleWarehouse._id === warehouse._id) {
              for (const singlePresentation of singleArticle.PRESENTACIONES) {
                if (singlePresentation._id === article.selectedPresentation) {
                  let presentationValue =
                    singlePresentation.ESMAYOR === "S"
                      ? value * singlePresentation.FACTOR2
                      : value / singlePresentation.FACTOR2;
                  this.articles[singleArticleIndex].defaultWarehouseArticles[
                    singleWarehouseIndex
                  ].CANTIDAD =
                    Number(
                      this.articles[singleArticleIndex]
                        .defaultWarehouseArticles[singleWarehouseIndex]
                        .CANTIDADINICIAL
                    ) + Number(presentationValue);

                  this.articles[singleArticleIndex].defaultWarehouseArticles[
                    singleWarehouseIndex
                  ].CANTIDAD_AGR = Number(presentationValue);
                }
              }
            }
          }
        }
      }
    },
    getAllDoneTransfers() {
      let transfersToDo = [];
      for (const [
        singleArticleIndex,
        singleArticle,
      ] of this.articles.entries()) {
        if (
          isNumber(singleArticle.defaultWarehouseArticles[0].CANTIDAD_AGR) &&
          singleArticle.defaultWarehouseArticles[0].CANTIDAD_AGR !== 0
        ) {
          transfersToDo.push({
            article: singleArticle._id,
            transfer: singleArticle.defaultWarehouseArticles[0],
          });
        }
      }
      this.newWarehouseTransfer.transfers = transfersToDo;
      return transfersToDo;
    },
    async transferArticles(confirm) {
      try {
        if (confirm) this.newWarehouseTransfer.STATUS_TRA = "C";
        if (!confirm) this.newWarehouseTransfer.STATUS_TRA = "T";
        let transfersToDo = this.getAllDoneTransfers();
        if (transfersToDo.length > 0) {
          let response = await this.$store.dispatch(
            "ADDARTICLEQUANTITY",
            this.newWarehouseTransfer
          );
          if (response.transfersDone && response.transfersDone.length > 0) {
            this.closeModal();
            this.$buefy.modal.open({
              canCancel: ["x"],
              parent: this,
              component: PrintMultipleMaterialMovement,
              props: {
                movementsInformation: response.transfersDoneFormatted,
                title: "Ajuste de inventario",
              },
              hasModalCard: false,
              customClass: "primary-modal-class",
              trapFocus: true,
              fullScreen: false,
              destroyOnHide: true,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al guardar el inventario a agregar de lo artículos en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "No hay inventario que realizar",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al guardar la transferencia de artículos en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    sortByWarehouseQuantity(a, b, isAsc) {
      if (isAsc) {
        return (
          Number(b.defaultWarehouseArticles[0].CANTIDAD) -
          Number(a.defaultWarehouseArticles[0].CANTIDAD)
        );
      } else {
        return (
          Number(a.defaultWarehouseArticles[0].CANTIDAD) -
          Number(b.defaultWarehouseArticles[0].CANTIDAD)
        );
      }
    },
    toogleShowEditted() {
      this.showOnlyEditted = !this.showOnlyEditted;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES.filter((singleWarehouse) => {
        return true;
      });
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        if (singleArticle.FE_ULTVTA) {
          articleWithProps.lastSaleFormat = moment(
            singleArticle.FE_ULTVTA
          ).format("DD-MM-YYYY HH:mm");
        }
        return articleWithProps;
      });
    },
    articlesFormatted() {
      return this.articles
        .map((singleArticle) => {
          return singleArticle;
        })
        .filter((singleArticle) => {
          if (!this.showOnlyEditted) {
            return true;
          }

          for (const singleWarehouse of singleArticle.defaultWarehouseArticles) {
            if (singleWarehouse.CANTIDAD_AGR !== 0) {
              return true;
            }
          }
          return false;
        });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
  watch: {
    /**
     * @desc Select default opened details, to open a specific warehouse
     */
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
