var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_c('b-field',{staticClass:"global-lists-controls",attrs:{"grouped":"","group-multiline":""}},[(
        _vm.companyPaymentPlanModules &&
        _vm.companyPaymentPlanModules.purchase.getArticlesInventory === 'S'
      )?_c('div',{staticClass:"control"},[(
          (_vm.articlesPaginated.length === 0 ||
            (_vm.warehouses.length <= 1 &&
              _vm.companyPaymentPlanModules &&
              _vm.companyPaymentPlanModules.main.importWarehousesArticles ===
                'S' &&
              _vm.permissions &&
              _vm.permissions.main.importWarehousesArticles === 'S')) &&
          _vm.type === 0
        )?_c('b-tooltip',{attrs:{"label":"Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos, solo se puede transferir artículos si se tiene más de un almacén","multilined":""}},[_c('b-button',{attrs:{"type":"is-primary","disabled":""}},[_vm._v("Importar inventario en almacenes")])],1):_vm._e(),(
          _vm.articlesPaginated.length > 0 &&
          _vm.warehouses.length > 1 &&
          _vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.main.importWarehousesArticles === 'S' &&
          _vm.permissions &&
          _vm.permissions.main.importWarehousesArticles === 'S' &&
          _vm.type === 0
        )?_c('b-button',{staticClass:"special-margin",attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startImport()}}},[_vm._v("Importar inventario en almacenes")]):_vm._e()],1):_vm._e(),(
        (_vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.purchase.transferArticles === 'S' &&
          _vm.permissions &&
          _vm.permissions.purchase.transferArticles === 'S' &&
          _vm.type === 0) ||
        (_vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.purchase.transferArticlesPending ===
            'S' &&
          _vm.permissions &&
          _vm.permissions.purchase.transferArticlesPending &&
          _vm.type === 0)
      )?_c('div',{staticClass:"control special-margin"},[(
          (_vm.articlesPaginated.length === 0 || _vm.warehouses.length <= 1) &&
          _vm.type === 0
        )?_c('b-tooltip',{attrs:{"label":"Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos, solo se puede transferir artículos si se tiene más de un almacén","multilined":""}},[_c('b-button',{staticClass:"special-margin",attrs:{"type":"is-primary","disabled":""}},[_vm._v("Transferir artículos entre almacenes")])],1):_vm._e(),(
          _vm.articlesPaginated.length > 0 && _vm.warehouses.length > 1 && _vm.type === 0
        )?_c('b-button',{staticClass:"special-margin",attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openTransferArticle()}}},[_vm._v("Transferir artículos entre almacenes")]):_vm._e()],1):_vm._e(),(
        (_vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.purchase.addArticlesQuantity === 'S' &&
          _vm.permissions &&
          _vm.permissions.purchase.addArticlesQuantity === 'S' &&
          _vm.type === 0) ||
        (_vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.purchase.addArticlesQuantityPending ===
            'S' &&
          _vm.permissions &&
          _vm.permissions.purchase.addArticlesQuantityPending === 'S' &&
          _vm.type === 0)
      )?_c('div',{staticClass:"control"},[(
          (_vm.articlesPaginated.length === 0 || _vm.warehouses.length === 0) &&
          _vm.type === 0
        )?_c('b-tooltip',{attrs:{"label":"Primero debes agregar artículos y almacenes al sistema o esperar a que el sistema cargue estos datos","multilined":""}},[_c('b-button',{attrs:{"type":"is-primary","disabled":""}},[_vm._v("Ajustes de inventario en almacén")])],1):_vm._e(),(_vm.articlesPaginated.length > 0 && _vm.warehouses.length && _vm.type === 0)?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.openAddArticleQuantity()}}},[_vm._v("Ajustes de inventario en almacén")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"control"},[_c('b-button',{attrs:{"type":"is-primary","loading":_vm.refreshLoading},on:{"click":function($event){return _vm.reloadInformation()}}},[_vm._v("Refrescar información")])],1),_c('div',{staticClass:"control"},[(
          _vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.main.exportWarehousesArticles === 'S' &&
          _vm.permissions &&
          _vm.permissions.main.exportWarehousesArticles === 'S' &&
          _vm.type === 0
        )?_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startExport()}}},[_vm._v("Exportar inventario en almacenes")]):_vm._e()],1),_c('div',{staticClass:"control"},[(
          _vm.companyPaymentPlanModules &&
          _vm.companyPaymentPlanModules.purchase.resetSalesInventory === 'S' &&
          _vm.permissions &&
          _vm.permissions.purchase.resetSalesInventory === 'S' &&
          _vm.type === 0
        )?_c('b-button',{staticClass:"special-margin",attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.startResetSalesInventory()}}},[_vm._v("Recalcular inventario de ventas")]):_vm._e()],1)]),_c('div',{staticClass:"global-lists-search"},[_c('div',{staticClass:"global-lists-filters-columns"},_vm._l((_vm.columns),function(singleColumn){return _c('b-checkbox',{key:singleColumn.label,model:{value:(singleColumn.display),callback:function ($$v) {_vm.$set(singleColumn, "display", $$v)},expression:"singleColumn.display"}},[_vm._v(" "+_vm._s(singleColumn.label)+" ")])}),1),_c('div',{staticClass:"global-lists-filters-container"})]),_c('div',{staticClass:"table-header"},[_vm._v(" Cantidad de Artículos: "),_c('b',[_vm._v(_vm._s(_vm.articlesPaginatedTotal))])]),_c('b-table',{ref:"WarehousesTable",attrs:{"pagination-position":"both","data":_vm.articlesPaginated,"striped":true,"bordered":true,"hoverable":true,"checked-rows":_vm.checkedWarehouses,"paginated":"","backend-pagination":"","total":_vm.articlesPaginatedTotal,"per-page":_vm.perPage,"opened-detailed":_vm.defaultOpenedDetails,"selected":_vm.selected,"detailed":"","detail-key":"_id","sort-icon":"arrow-up","sort-icon-size":"is-small","default-sort-direction":"asc","checkbox-position":"left","default-sort":"CLAVE_ART","scrollable":""},on:{"update:checkedRows":function($event){_vm.checkedWarehouses=$event},"update:checked-rows":function($event){_vm.checkedWarehouses=$event},"page-change":function (page) {
        this$1.currentPage = page;
        this$1.reloadArticlesInformation();
      },"update:selected":function($event){_vm.selected=$event},"details-open":function (row) { return _vm.$buefy.toast.open({
          message: ("Información de " + (row.CLAVE_ART)),
          type: 'is-success',
          position: 'is-bottom',
        }); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("No hay almacenes or árticulos")])]},proxy:true},{key:"default",fn:function(props){return [_vm._l((_vm.columns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"sortable":column.sortable,"visible":column.display,"label":column.label,"searchable":column.searchable},scopedSlots:_vm._u([(column.searchable)?{key:"searchable",fn:function(props){return [_c('b-field',[_c('b-input',{on:{"input":function($event){return _vm.reloadArticlesInformation()}},model:{value:(_vm.searchFields[props.column.field]),callback:function ($$v) {_vm.$set(_vm.searchFields, props.column.field, $$v)},expression:"searchFields[props.column.field]"}})],1)]}}:null],null,true)},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),_c('span',[_vm._v(_vm._s(props.row[column.field]))])])}),_vm._l((_vm.warehousesColumns),function(column){return _c('b-table-column',_vm._b({key:column.field,attrs:{"sortable":column.sortable,"visible":column.display,"label":column.label,"searchable":column.searchable}},'b-table-column',column,false),[(!_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s("Sin información"))]):_vm._e(),(_vm.checkIfValid(props.row[column.field]))?_c('span',[_vm._v(_vm._s(props.row[column.field]))]):_vm._e()])})]}},{key:"detail",fn:function(props){return [_c('article',{staticClass:"media"},[_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"image is-64x64"})]),_c('div',{staticClass:"content"},[(_vm.type === 0)?_c('div',[_vm._l((props.row
                .defaultWarehouseArticles),function(singlePresentationWarehouse){return _c('div',{key:singlePresentationWarehouse._id,staticClass:"special-big-margin single-articles-prices"},[_c('h3',{staticClass:"title-expandable-item"},[_c('strong',[_vm._v("Información general de almacén "+_vm._s(singlePresentationWarehouse.CLAVE_ALMACEN))]),_c('strong',[_c('span',{staticClass:"global-form-hint-color"})])]),_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Inventario minimo (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any"},model:{value:(singlePresentationWarehouse.MINIMO),callback:function ($$v) {_vm.$set(singlePresentationWarehouse, "MINIMO", $$v)},expression:"singlePresentationWarehouse.MINIMO"}})],1),_c('b-field',{attrs:{"label":"Inventario máximo (Opcional)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-8-box-multiple-outline","type":"number","step":"any"},model:{value:(singlePresentationWarehouse.MAXIMO),callback:function ($$v) {_vm.$set(singlePresentationWarehouse, "MAXIMO", $$v)},expression:"singlePresentationWarehouse.MAXIMO"}})],1)],1),_c('div',{staticClass:"controls"},[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":function($event){return _vm.saveWarehouseMinMax(
                      props.row,
                      singlePresentationWarehouse
                    )}}},[_vm._v("Guardar cambios")])],1)],1)}),_vm._l((props.row.PRESENTACIONES),function(singlePresentation){return _c('div',{key:singlePresentation._id,staticClass:"special-big-margin single-articles-prices"},[_c('h3',{staticClass:"title-expandable-item"},[_c('strong',[_vm._v("Presentación ")]),_c('strong',[_c('span',{staticClass:"global-form-hint-color"},[_vm._v(_vm._s(singlePresentation.NOMBRE))])])]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Valor de medida base del artículo (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any","value":singlePresentation.FACTOR1,"disabled":""}})],1),_c('b-field',{attrs:{"label":"Cantidad de medidas base que equivale la presentación (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-2-circle-outline","type":"number","step":"any","value":singlePresentation.FACTOR2,"disabled":""}})],1)],1),_vm._l((props.row
                  .defaultWarehouseArticles),function(singlePresentationWarehouse){return _c('div',{key:singlePresentationWarehouse._id},[_c('h3',{staticClass:"title-expandable-item"},[_c('strong',[_vm._v("Inventario de Presentación ")]),_c('strong',[_c('span',{staticClass:"global-form-hint-color"},[_vm._v(_vm._s(singlePresentation.NOMBRE))]),_vm._v(" en almacén "),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(_vm._s(singlePresentationWarehouse.CLAVE_ALMACEN))])])]),_c('b-field',{staticClass:"special-margin",attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Inventario en medida base del artículo (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-1-circle-outline","type":"number","step":"any","value":singlePresentationWarehouse.CANTIDAD,"disabled":""}})],1),_c('b-field',{attrs:{"label":"Inventario en presentación (Requerido)","expanded":"","label-position":"inside"}},[_c('b-input',{attrs:{"placeholder":"Ejemplo: 40","expanded":"","icon":"numeric-2-circle-outline","type":"number","step":"any","value":_vm.getPresentationInventory(
                          singlePresentationWarehouse,
                          singlePresentation
                        ),"disabled":""}})],1)],1)],1)})],2)})],2):_vm._e(),(_vm.type === 1)?_c('div',[_c('p',[_vm._v("No hay información que mostrar")])]):_vm._e()])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }